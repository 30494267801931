<template>
  <v-container>
    <v-row class="mb-2">
      <v-col>
        <v-data-table
          class="draft-engagement-btn"
          dense
          :headers="vDataTableHeaders"
          :items="[processedCampaignVersion1, processedCampaignVersion2]"
          item-key="id"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <AbstractDraftMetricDataTable
          :title="'Resultados Estimados Totales'"
          :buttonTag="changeUnitsButtonT1Tag"
          :hideFooter="true"
          :items="t1Metrics"
          :headers="tHeaders.t1Headers"
          @buttonClick="setMetricUnits('t1')"
        />
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <AbstractDraftMetricDataTable
          :title="'Breakdown de Costos'"
          :hideFooter="true"
          :items="t4Metrics"
          :headers="tHeaders.t4Headers"
        />
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <AbstractDraftMetricDataTable
          :title="
            'Rentabilidad Por Cultivo ' +
            processedCampaignVersion1.processedName
          "
          :buttonTag="changeUnitsButton1Tag"
          :hideFooter="true"
          :items="campaignVersion1T3Metrics"
          :headers="tHeaders.t3Headers1"
          :headersWidth="124"
          @buttonClick="setMetricUnits(campaignVersionId1)"
        />
      </v-col>
      <v-col>
        <AbstractDraftMetricDataTable
          :title="
            'Rentabilidad Por Cultivo ' +
            processedCampaignVersion2.processedName
          "
          :buttonTag="changeUnitsButton2Tag"
          :hideFooter="true"
          :items="campaignVersion2T3Metrics"
          :headers="tHeaders.t3Headers2"
          :headersWidth="124"
          @buttonClick="setMetricUnits(campaignVersionId2)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbstractDraftMetricDataTable from "../components/Abstract/AbstractDraftMetricDataTable.vue";
import { mapGetters } from "vuex";
import { parseToLocaleString } from "../managrx/utils";
export default {
  name: "CampaignVersionCompare",
  beforeMount() {
    let idArray = [this.campaignVersionId1, this.campaignVersionId2];
    this.$store.dispatch(
      "getSingleAbstractCampaign",
      this.$route.params.abstractCampaignId
    ),
      this.$store.dispatch("getMetricsForCampaignVersionComparison", {
        abstractCampaignId: this.$route.params.abstractCampaignId,
        campaignVersionsIdArray: idArray,
      });
    this.$store.dispatch("getCampaignVersionByIdArray", idArray);
  },
  data() {
    return {
      changeUnitsButtonT1Tag: "USD/ha",
      isUsdTotalMetricT1: true,
      metricUnitsT1: "USD",
      changeUnitsButton1Tag: "USD",
      changeUnitsButton2Tag: "USD",
      isUsdTotalMetric1T3: false,
      isUsdTotalMetric2T3: false,
      metricUnits1T3: "USD/ha",
      metricUnits2T3: "USD/ha",
      campaignVersionId1: this.$route.params.id1,
      campaignVersionId2: this.$route.params.id2,
      vDataTableHeaders: [
        { name: "ID", value: "id" },
        { name: "Nombre", value: "processedName" },
        { name: "Versión", value: "version" },
        { name: "Fecha de inicio", value: "startDate" },
        { name: "Fecha de finalización", value: "endDate" },
      ].map((i) => {
        return { text: i.name, value: i.value };
      }),
    };
  },
  methods: {
    setMetricUnits(id) {
      if (id == this.campaignVersionId1) {
        if (this.isUsdTotalMetric1T3) {
          this.changeUnitsButton1Tag = "USD";
          this.isUsdTotalMetric1T3 = false;
          this.metricUnits2T3 = "USD/ha";
        } else {
          this.changeUnitsButton1Tag = "USD/ha";
          this.isUsdTotalMetric1T3 = true;
          this.metricUnits2T3 = "USD";
        }
      } else if (id == this.campaignVersionId2) {
        if (this.isUsdTotalMetric2T3) {
          this.changeUnitsButton2Tag = "USD";
          this.isUsdTotalMetric2T3 = false;
          this.metricUnits2T3 = "USD/ha";
        } else {
          this.changeUnitsButton2Tag = "USD/ha";
          this.isUsdTotalMetric2T3 = true;
          this.metricUnits2T3 = "USD";
        }
      } else if (id == "t1") {
        if (this.isUsdTotalMetricT1) {
          this.changeUnitsButtonT1Tag = "USD";
          this.isUsdTotalMetricT1 = false;
          this.metricUnitsT1 = "USD/ha";
        } else {
          this.changeUnitsButtonT1Tag = "USD/ha";
          this.isUsdTotalMetricT1 = true;
          this.metricUnitsT1 = "USD";
        }
      }
    },
  },

  components: { AbstractDraftMetricDataTable },
  computed: {
    ...mapGetters([
      "t1MetricsCampaignComparison",
      "t4MetricsCampaignComparison",
      "t3MetricsCampaignComparison",
      "campaignVersionsToCompare",
      "singleAbstractCampaign",
    ]),
    processedCampaignVersion1() {
      return {
        id: this.campaignVersionsToCompare?.[0]?.id,
        version: this.campaignVersionsToCompare?.[0]?.version,
        name: this.singleAbstractCampaign?.name,
        processedName:
          this.singleAbstractCampaign?.name +
          " v" +
          this.campaignVersionsToCompare?.[0]?.version,
        endDate: this.singleAbstractCampaign?.endDate?.slice(0, 10),
        startDate: this.singleAbstractCampaign?.startDate?.slice(0, 10),
      };
    },
    processedCampaignVersion2() {
      return {
        id: this.campaignVersionsToCompare?.[1]?.id,
        version: this.campaignVersionsToCompare?.[1]?.version,
        name: this.singleAbstractCampaign?.name,
        processedName:
          this.singleAbstractCampaign?.name +
          " v" +
          this.campaignVersionsToCompare?.[1]?.version,
        endDate: this.singleAbstractCampaign?.endDate?.slice(0, 10),
        startDate: this.singleAbstractCampaign?.startDate?.slice(0, 10),
      };
    },
    t1MetricsToLocalString() {
      let t1Metrics = this.t1MetricsCampaignComparison;
      t1Metrics?.forEach((element) => {
        element["version_a"] = element["version_a"].toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element["version_b"] = element["version_b"].toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element["delta"] = element["delta"].toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        if (element?.["delta_ha"]) {
          element["version_a_ha"] = element["version_a_ha"].toLocaleString(
            "en-US",
            {
              timeZone: "America/Argentina/Buenos_Aires",
            }
          );
          element["version_b_ha"] = element["version_b_ha"].toLocaleString(
            "en-US",
            {
              timeZone: "America/Argentina/Buenos_Aires",
            }
          );
          element["delta_ha"] = element["delta_ha"].toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          });
        }
      });

      return t1Metrics;
    },
    t1Metrics() {
      let t1MetricsValues = this.t1MetricsToLocalString;
      let t1Metrics;
      if (this.isUsdTotalMetricT1) {
        t1Metrics = t1MetricsValues;
      } else {
        t1Metrics = t1MetricsValues.map((element) => {
          return {
            version_a:
              element.unit == "%"
                ? element?.["version_a"]
                : element?.["version_a_ha"],
            version_b:
              element.unit == "%"
                ? element?.["version_b"]
                : element?.["version_b_ha"],
            unit:
              element.unit == "%" ? element?.["unit"] : element?.["unit_ha"],
            key: element?.["key"],
            delta:
              element.unit == "%" ? element?.["delta"] : element?.["delta_ha"],
          };
        });
      }
      return t1Metrics;
    },
    t4Metrics() {
      let t4Metrics = this.t4MetricsCampaignComparison;
      t4Metrics.forEach((element) => {
        element["version_a"] = parseToLocaleString(element["version_a"]);
        element["version_b"] = parseToLocaleString(element["version_b"]);
        element["version_a_ha"] = parseToLocaleString(element["version_a_ha"]);
        element["version_b_ha"] = parseToLocaleString(element["version_b_ha"]);
        element["delta"] = parseToLocaleString(element["delta"]);
        element["delta_ha"] = parseToLocaleString(element["delta_ha"]);
      });
      return t4Metrics;
    },
    tHeaders() {
      return {
        t1Headers: [
          { text: "Indicator", value: "key", class: "secondary white--text" },
          {
            text: `${this.processedCampaignVersion1.processedName}`,

            value: "version_a",
            class: "secondary white--text",
          },
          {
            text: `${this.processedCampaignVersion2.processedName}`,

            value: "version_b",
            class: "secondary white--text",
          },
          {
            text: "delta",
            value: "delta",
            class: "secondary white--text",
          },
          {
            text: "Unit",
            value: "unit",
            class: "secondary white--text",
          },
        ],
        t4Headers: [
          { text: "Metric", value: "key", class: "secondary white--text" },
          {
            text: `${this.processedCampaignVersion1.processedName}`,
            value: "version_a",
            class: "secondary white--text",
          },
          {
            text: `${this.processedCampaignVersion2.processedName}`,
            value: "version_b",
            class: "secondary white--text",
          },
          {
            text: `${this.processedCampaignVersion1.processedName} ha`,
            value: "version_a_ha",
            class: "secondary white--text",
          },
          {
            text: `${this.processedCampaignVersion2.processedName} ha`,
            value: "version_b_ha",
            class: "secondary white--text",
          },
          { text: "delta", value: "delta", class: "secondary white--text" },
          {
            text: "delta ha",
            value: "delta_ha",
            class: "secondary white--text",
          },
        ],
        t3Headers1: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Hectáreas Asignadas",
            value: "assignedHectares",
            class: "secondary white--text",
          },
          {
            text: "Margen " + this.metricUnits1T3,
            value: !this.isUsdTotalMetric1T3
              ? "profitsByHectare"
              : "totalProfits",
            class: "secondary white--text",
          },
          {
            text: "Costo Total " + this.metricUnits1T3,
            value: !this.isUsdTotalMetric1T3
              ? "totalCostsByHectare"
              : "totalCosts",
            class: "secondary white--text",
          },
          {
            text: "Costo Directo " + this.metricUnits1T3,
            value: !this.isUsdTotalMetric1T3
              ? "directCostsByHectare"
              : "totalDirectCosts",
            class: "secondary white--text",
          },
          {
            text: "Autofinanciables " + this.metricUnits1T3,
            value: !this.isUsdTotalMetric1T3
              ? "autofinanciableCostsByHectare"
              : "totalAutofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "No Autofinanciables " + this.metricUnits1T3,
            value: !this.isUsdTotalMetric1T3
              ? "nonautofinanciableCostsByHectare"
              : "totalNonautofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "Iva Neto " + this.metricUnits1T3,
            value: !this.isUsdTotalMetric1T3
              ? "ivaNetByHectares"
              : "totalIvaNet",
            class: "secondary white--text",
          },
          {
            text: "Ingreso Neto/Gasto %",
            value: "netIncomeOverExpensesPercent",
            class: "secondary white--text",
          },
        ],
        t3Headers2: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Hectáreas Asignadas",
            value: "assignedHectares",
            class: "secondary white--text",
          },
          {
            text: "Margen " + this.metricUnits2T3,
            value: !this.isUsdTotalMetric2T3
              ? "profitsByHectare"
              : "totalProfits",
            class: "secondary white--text",
          },
          {
            text: "Costo Total " + this.metricUnits2T3,
            value: !this.isUsdTotalMetric2T3
              ? "totalCostsByHectare"
              : "totalCosts",
            class: "secondary white--text",
          },
          {
            text: "Costo Directo " + this.metricUnits2T3,
            value: !this.isUsdTotalMetric2T3
              ? "directCostsByHectare"
              : "totalDirectCosts",
            class: "secondary white--text",
          },
          {
            text: "Autofinanciables " + this.metricUnits2T3,
            value: !this.isUsdTotalMetric2T3
              ? "autofinanciableCostsByHectare"
              : "totalAutofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "No Autofinanciables " + this.metricUnits2T3,
            value: !this.isUsdTotalMetric2T3
              ? "nonautofinanciableCostsByHectare"
              : "totalNonautofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "Iva Neto " + this.metricUnits2T3,
            value: !this.isUsdTotalMetric2T3
              ? "ivaNetByHectares"
              : "totalIvaNet",
            class: "secondary white--text",
          },
          {
            text: "Ingreso Neto/Gasto %",
            value: "netIncomeOverExpensesPercent",
            class: "secondary white--text",
          },
        ],
      };
    },
    campaignVersion1T3Metrics() {
      let t3Metrics = this.t3MetricsCampaignComparison[this.campaignVersionId1];

      t3Metrics.forEach((element) => {
        element.indifferenceQqha = (
          element.indifferenceQqha || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalProfits = (element.totalProfits || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.totalCosts = (element.totalCosts || 0).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalSalesExpenses = (
          element.totalSalesExpenses || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalDirectCosts = (
          element.totalDirectCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalAutofinanciableCosts = (
          element.totalAutofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalNonautofinanciableCosts = (
          element.totalNonautofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.profitsByHectare = (
          element.profitsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalCostsByHectare = (
          element.totalCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.salesExpensesByHectare = (
          element.salesExpensesByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.directCostsByHectare = (
          element.directCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.autofinanciableCostsByHectare = (
          element.autofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.nonautofinanciableCostsByHectare = (
          element.nonautofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalIvaNet = (element.totalIvaNet || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.ivaNetByHectare = (element.ivaNetByHectare || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );

        element.netIncomeOverExpensesPercent = (
          element.netIncomeOverExpensesPercent || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });

      return t3Metrics;
    },
    campaignVersion2T3Metrics() {
      let t3Metrics = this.t3MetricsCampaignComparison[this.campaignVersionId2];

      t3Metrics.forEach((element) => {
        element.indifferenceQqha = (
          element.indifferenceQqha || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalProfits = (element.totalProfits || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.totalCosts = (element.totalCosts || 0).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalSalesExpenses = (
          element.totalSalesExpenses || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalDirectCosts = (
          element.totalDirectCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalAutofinanciableCosts = (
          element.totalAutofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalNonautofinanciableCosts = (
          element.totalNonautofinanciableCosts || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.profitsByHectare = (
          element.profitsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalCostsByHectare = (
          element.totalCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.salesExpensesByHectare = (
          element.salesExpensesByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.directCostsByHectare = (
          element.directCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.autofinanciableCostsByHectare = (
          element.autofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.nonautofinanciableCostsByHectare = (
          element.nonautofinanciableCostsByHectare || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.totalIvaNet = (element.totalIvaNet || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
        element.ivaNetByHectare = (element.ivaNetByHectare || 0).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );

        element.netIncomeOverExpensesPercent = (
          element.netIncomeOverExpensesPercent || 0
        ).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });

      return t3Metrics;
    },
  },
};
</script>

<style></style>
