<template>
  <div>
    <div class="d-flex align-center pa-0 ma-0 mb-2">
      <h2 class="caption">{{ title }}</h2>
      <v-btn
        v-if="buttonTag"
        elevation="0"
        class="ms-1"
        x-small
        @click="emitButtonEvent"
        >{{ buttonTag }}</v-btn
      >

      <v-btn
        v-if="hasGroupByCategory"
        class="ms-1"
        elevation="0"
        :class="{}"
        x-small
        @click="updateGroupBy"
        >{{ groupByText }}</v-btn
      >
      <v-btn
        v-if="buttonShow"
        x-small
        @click="emitButtonShowClick"
        elevation="0"
        class="ms-1"
      >
        <v-icon small color="grey darken-1">mdi-format-paint</v-icon>
      </v-btn>
      <v-text-field
        v-if="hasSearch"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        class="ps-4"
      ></v-text-field>
    </div>

    <v-data-table
      :search="search"
      :headers="headersArray"
      :items="items"
      :disable-pagination="hideFooter"
      :group-by="categoryValue"
      :hide-default-footer="hideFooter"
      :sort-by="orderBy"
      :sort-desc="desc"
    ></v-data-table>
  </div>
</template>

<script>
export default {
  name: "AbstractDraftMetricDataTable",
  props: {
    title: String,
    buttonShow: Boolean,
    buttonTag: String,
    hideFooter: Boolean,
    items: Array,
    headers: Array,
    headersWidth: Number,
    orderBy: String,
    desc: Boolean,
    groupBy: String,
    search: String,
    hasSearch: Boolean,
    grupByStatus: Boolean,
  },
  data() {
    return {
      hasGroupBy: this.grupByStatus ?? false,
    };
  },
  methods: {
    emitButtonShowClick() {
      this.$emit("buttonShowClick");
    },
    emitButtonEvent() {
      this.$emit("buttonClick");
    },
    updateGroupBy() {
      this.hasGroupBy = !this.hasGroupBy;
      this.$emit("ungroup");
    },
  },

  computed: {
    hasGroupByCategory() {
      if (this.groupBy) {
        return true;
      }
      return false;
    },
    groupByText() {
      if (!this.hasGroupBy) {
        return "Agrupar";
      }
      return "Desagrupar";
    },
    categoryValue() {
      if (this.hasGroupBy) {
        return this.groupBy;
      }
      return null;
    },

    headersArray() {
      let headersArray = this.headers;
      if (this.headersWidth) {
        headersArray = headersArray.map((element) => {
          return { ...element, width: `${this.headersWidth}px` };
        });
      }
      return headersArray;
    },
  },
};
</script>

<style></style>
